<template>
  <div class="container">
    <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0824.jpeg" style="max-height: 800px" />
  </div>
  <div class="container mt-5">
    <div class="text-center">
      <div class="row">
        <span><h1>Welkom in ons nostalgisch appartement Hof Buitenlust, in Zeeland</h1></span>
        <p class="lead">
          Het appartement bevindt zich in de authentieke boerenschuur op het erf. Buitenlust is in 1743 gebouwd door een baron en
          barones, als Buitenplaats. Doordat het echtpaar kinderloos was, is het na hun overlijden verkocht aan een boer, die de
          schuur erbij heeft laten bouwen. In Hof Buitenlust proeft u de nostalgie van de vervlogen jaren. Hoe leuk is het om te
          ontbijten op de plaats waar vroeger de koeien en paarden stonden? Om samen koffie te drinken, waar eens de lammetjes
          ronddartelden? En heerlijk onder de dekens te kruipen op de plek waar ooit het hooi werd opgeslagen? Dankzij de
          renovatie in 2020 is Hof Buitenlust nu een comfortabel vakantieverblijf, waar alles de sfeer van de Buitenplaats en het
          boerenleven uitademt.
        </p>
      </div>
    </div>
  </div>
  <hr class="featurette-divider col-md-10 mx-auto mt-5" />
  <div class="container">
    <div class="row w d-flex justify-content-center">
      <div class="col-md-4 mb-3">
        <img class="bd-placeholder-img d-block w-100 zooming-image" src="../assets/DSC_0814.jpeg" style="max-height: 800px" />
      </div>
      <div class="col-md-4 mb-3">
        <img class="bd-placeholder-img d-block w-100 zooming-image" src="../assets/DSC_0818.jpeg" style="max-height: 800px" />
      </div>
      <div class="col-md-4 mb-3">
        <img class="bd-placeholder-img d-block w-100 zooming-image" src="../assets/DSC_0824.jpeg" style="max-height: 800px" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
};
</script>
<style>
.header {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  background: url("../assets/DSC_0824.jpeg") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
